@font-face {
  font-family: Everett;
  font-weight: 300;
  src: url("./fonts/Everett-Light.otf") format("opentype");
}
@font-face {
  font-family: Everett;
  font-weight: 400;
  src: url("./fonts/Everett-Regular.otf") format("opentype");
}
@font-face {
  font-family: Everett;
  font-weight: 600;
  src: url("./fonts/Everett-Medium.otf") format("opentype");
}

@font-face {
  font-family: Everett;
  font-weight: 700;
  src: url("./fonts/Everett-Jana.otf") format("opentype");
}

@import url("https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;500;600;700;800&display=swap");

body {
  margin: 0;
  font-family: "Shippori Mincho", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
